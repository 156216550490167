<template>
  <Header class="hdr">
    <kendo-window
      :ref="'preferencesWindow'"
      :width="'1000px'"
      :visible="false"
      :modal="true"
      style="display:none">
      <div class="form-entry-label">
        <h1>General Preferences</h1>
      </div>
      <div class="form-entry-label">
        Default Project at Login
      </div>
      <div>
        <input type="text">
      </div>
      <div class="form-entry-label">
        Publication Card Location (left/right)
      </div>
      <div>
        <input type="text">
      </div>
      <div class="form-entry-label">
        Default Items per Page
      </div>
      <div>
        <input type="text">
      </div>
      <div class="form-entry-label">
        Display forms/full text cards
      </div>
      <div>
        Attach/Detach
      </div>
      <div>&nbsp;</div>
      <div class="form-entry-label">
        <h1>For current project</h1>
      </div>
      <div class="form-entry-label">
        Manage/Review option: Visualization of cards per stage
      </div>
      <div>
      <kendo-datasource
          :ref="'stageDataSource'"
          :data="stageDataSource.data">
        </kendo-datasource>
        <kendo-grid
          :id="'manageStageGrid'"
          :ref="'manageStageGrid'"
          :data-source-ref="'stageDataSource'"
          :columns="columns"
          :data-bound="stageCardDataBound"
          style="width: calc(100% - 10px);">
        </kendo-grid>
      </div>
      <div class="action-buttons-nav">
        <b-button type="submit" variant="primary" size="sm">Reset to Project Default</b-button>
        <b-button type="submit" variant="primary" size="sm">Save</b-button>
        <b-button type="submit" variant="primary" size="sm">Cancel</b-button>
      </div>
    </kendo-window>
    <div :class="toggleClass()" @click="selectHome()">
      <div class="hdr-title-text">{{ this.$store.state.title }}</div>
      <div class="hdr-title-project">
       {{ this.$store.state.isLogged ? this.$store.state.selectedProject === '' ? 'Current Project:' + ' None' : 'Current Project:' + this.$store.state.selectedProject : '' }}
      </div>
    </div>
    <app-navigation v-if="this.$store.state.isLogged"/>
    <app-actions v-if="this.$store.state.isLogged"/>
    <div class="hdr-profile">
      <kendo-menu
        :id="'profileMenu'"
        :ref="'profileMenuWindow'"
        v-if="this.$store.state.isLogged"
        @select="onSelect">
        <li>
          <span class="k-icon k-i-user"></span> Welcome, {{ this.$store.state.userName }}
          <ul>
            <li>Preferences</li>
            <li>Logout</li>
          </ul>
        </li>
      </kendo-menu>
    </div>
    <div class="hdr-card-selector">
      <kendo-menu
        :id="'cardSelector'"
        :ref="'cardSelector'"
        v-if="this.$store.state.selectedAction === 'manage-forms'"
        @select="onCardSelect">
        <li>
          <span class="k-icon k-i-file-txt"></span>
          <ul>
            <li>
              <div id="CheckBoxClient_w">
                <div id="CheckBoxClient_inpwrap" class="kct-input-wrapper">
                  <input type="checkbox" disabled="" name="PublicationStageReview" checked="" id="PublicationStageReview" data-kct-type="checkbox" class="form-control k-checkbox" style="width:200px;">
                  <label title="Option 1" class="k-checkbox-label" for="PublicationStageReview">Publications</label>
                </div>
              </div>
            </li>
            <li>
              <div id="CheckBoxClient_w">
                <div id="CheckBoxClient_inpwrap" class="kct-input-wrapper">
                  <input type="checkbox" disabled="" name="AnnotationView" checked="" id="AnnotationView" data-kct-type="checkbox" class="form-control k-checkbox" value="SLR.AnnotationView" style="width:200px;">
                  <label title="Option 1" class=" k-checkbox-label" for="AnnotationView">Annotations</label>
                </div>
              </div>
            </li>
            <li>
              <div id="CheckBoxClient_w">
                <div id="CheckBoxClient_inpwrap" class="kct-input-wrapper">
                  <input type="checkbox" name="CitationView" checked="" id="CitationView" data-kct-type="checkbox" class="form-control k-checkbox" value="SLR.CitationView" style="width:200px;">
                  <label title="Option 1" class=" k-checkbox-label" for="CitationView">Citation</label>
                </div>
              </div>
            </li>
            <li>
              <div id="CheckBoxClient_w">
                <div id="CheckBoxClient_inpwrap" class="kct-input-wrapper">
                  <input type="checkbox" name="AbstractView" checked="" id="AbstractView" data-kct-type="checkbox" class="form-control k-checkbox" value="SLR.CitationView" style="width:200px;">
                  <label title="Option 1" class=" k-checkbox-label" for="AbstractView">Abstract</label>
                </div>
              </div>
            </li>
            <li>
              <div id="CheckBoxClient_w">
                <div id="CheckBoxClient_inpwrap" class="kct-input-wrapper">
                  <input type="checkbox" name="FormsView" checked="" id="FormsView" data-kct-type="checkbox" class="form-control k-checkbox" value="SLR.CitationView" style="width:200px;">
                  <label title="Option 1" class=" k-checkbox-label" for="FormsView">Forms</label>
                </div>
              </div>
            </li>
            <li>
              <div id="CheckBoxClient_w">
                <div id="CheckBoxClient_inpwrap" class="kct-input-wrapper">
                  <input type="checkbox" name="FullTextView" checked="" id="FullTextView" data-kct-type="checkbox" class="form-control k-checkbox" value="SLR.CitationView" style="width:200px;">
                  <label title="Option 1" class=" k-checkbox-label" for="FullTextView">Full Text</label>
                </div>
              </div>
            </li>
            <li>
              <a href="javascript:void(0);" alt="Apply Card View" data-value="Apply" class="btn-full btn k-button" data-kct-action="ApplyCardView" icon-name="" form="CardView" validate="true" is-default="true" is-button="true" id="frmCardView_btnCardView" title="Apply Card View" style="cursor: pointer; z-index: 2000;">Apply</a>
            </li>
          </ul>
        </li>
      </kendo-menu>
    </div>
  </Header>
</template>

<script>
import AppNavigation from '@/components/AppNavigation'
import AppActions from '@/components/AppActions'
import CardDataSource from '@/assets/data/Card.json'
import StageDataSource from '@/assets/data/Stage.json'
import StageCardDataSource from '@/assets/data/StageCard.json'

export default {
  name: 'app-header',
  components: {
    AppNavigation,
    AppActions
  },
  data () {
    return {
      preferencesWindowWidget: {},
      profileMenuWindowWidget: {},
      fieldTypes: [
        { value: 'citationFields', text: 'Citation Fields' },
        { value: 'studyFields', text: 'Study Fields' },
        { value: 'otherPublicationFields', text: 'Other Publication Fields' }
      ],
      fields: [
        { field: 'PublicationIdentity', title: 'Publication ID', width: '120px', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'Author', title: 'Author', width: '120px', template: '<div id="truncate">#:Author#</div>', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'Year', title: 'Year', width: '80px', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'Title', title: 'Title', width: '300px', template: '<div id="truncate">#:Title#</div>', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'abstract', title: 'Abstract', width: '400px', template: '<div id="truncate">#:Abstract#</div>', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'TypeOfPublication', title: 'Type Of Publication', width: '200px', template: '<div id="truncate">#:TypeOfPublication#</div>', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'Volume', title: 'Volume', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'Issue', title: 'Issue', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'Pages', title: 'Page(s)', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
        { title: 'Conference Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
        { title: 'Date(s) of Conference', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
        { title: 'Report Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
        { title: 'Organizing Body', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
        { title: 'Date of Report', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'DatabaseName', title: 'Name of Database', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'AccessionNumber', title: 'Accession Number', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
        { field: 'DOI', title: 'DOI', width: '200px', template: '<div id="truncate">#:DOI#</div>', headerAttributes: { class: 'grid-header-de1' } }
      ],
      columns: this.generateColumns(),
      stageDataSource: StageDataSource
    }
  },
  methods: {
    selectHome: function () {
      if (this.$store.state.userName !== 'admin') {
        this.$store.state.selectedNavigation = ''
        this.$store.state.selectedAction = ''
        this.$router.push('/Secure/Home')
      }
    },
    stageCardDataBound: function () {
      StageCardDataSource.data.forEach(function (value) {
        var elementId = 'sc_' + value.StageIdentity + '_' + value.CardIdentity
        if (document.getElementById(elementId) !== null) {
          document.getElementById(elementId).setAttribute('checked', '')
        }
      })
    },
    generateColumns: function () {
      var columnsObject = []
      columnsObject.push({ field: 'stageName', title: 'Stages', width: '140px' })
      CardDataSource.data.forEach(function (value) {
        columnsObject.push({ field: 'CardIdentity_' + value.CardIdentity, title: value.CardName, template: '<input type="checkbox" id="sc_#: StageIdentity #_' + value.CardIdentity + '">', width: '1fr' })
      })
      return columnsObject
    },
    logOut: function () {
      this.$store.state.selectedProject = ''
      this.$store.state.selectedNavigation = ''
      this.$store.state.selectedAction = ''
      this.$store.state.showActions = false
      this.$store.state.isLogged = false
      this.$store.state.userName = ''
      this.$store.state.selectedDataItems = []
      this.$router.push({ path: '/' })
    },
    onSelect: function (e) {
      if (e.item.firstChild.innerText === 'Preferences') {
        this.preferencesWindowWidget
          .title('User Preferences')
          .open()
          .center()
      }
      if (e.item.firstChild.innerText === 'Logout') {
        this.logOut()
      }
    },
    onCardSelect: function (e) {
      if (e.item.firstChild.innerText === 'Citation') {
        console.log('Citation')
      }
      if (e.item.firstChild.innerText === 'Abstract') {
        console.log('Abstract')
      }
      if (e.item.firstChild.innerText === 'Forms') {
        console.log('Forms')
      }
      if (e.item.firstChild.innerText === 'Full Text') {
        console.log('Full Text')
      }
    },
    toggleClass: function () {
      if (this.$store.state.userName === 'admin') {
        return 'hdr-title-disabled'
      } else {
        return 'hdr-title'
      }
    }
  }
}
</script>
